<template>
	<div class="full-page-container">
		<main
			id="content"
			class="page-container"
		>
			<slot />
		</main>
	</div>
</template>

<script setup>
onMounted(() => {});
</script>
